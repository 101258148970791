import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router";

function LoginScreen() {
  // React States
  const [errorMessages, setErrorMessages] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [player, setPlayer] = useState({});
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const userName = e.target[0].value;
    const password = e.target[1].value;
    try {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`, {
          username: userName,
          password: password,
        })
        .then((res) => {
          if (res.data.success) {
            setPlayer(res.data.data.player);
            localStorage.setItem("accessToken", res.data.data.token);
            navigate(`/answer`);
          } else {
            setErrorMessages("wrong credentials");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const renderForm = (
    <div className="form">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="input-container">
          <label>Username </label>
          <input type="text" name="username" required />
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" required />
        </div>
        <div className="text-danger mx-auto errorContainer">
          {errorMessages && errorMessages}
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  return (
    <div className="loginPage">
      <div className="login-form">
        <div className="title">Sign In</div>
        {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
      </div>
    </div>
  );
}

export default LoginScreen;
