/* eslint-disable no-unused-expressions */
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TabScreen from "./TabScreen";
import LoginScreen from "./LoginScreen";
import Logout from "./Logout";

const MainComponent = () => {
  return (
    <div className="mainCont">
      <BrowserRouter>
        <Routes>
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<LoginScreen />} />
          <Route exact path="/answer" element={<TabScreen />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default MainComponent;
