/* eslint-disable no-unused-expressions */
import axios from "axios";
import React, { useEffect, useState } from "react";
import RefreshIcon from "./RefreshIcon";
import TabAnswerBtn from "../components/TabAnswerBtn";
import { useNavigate } from "react-router";

const TabScreen = () => {
  const [disabled, setDisabled] = useState(false);
  const [disabledStyle, setDisabledStyle] = useState(false);
  const [game, setGame] = useState(1);
  const [questionAvailable, setQuestionAvailable] = useState(true);
  const [buzzerClicked, setBuzzerClicked] = useState(false);
  const [message, setMessage] = useState("");
  const [player, setPlayer] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    !token && navigate("/");
  }, []);

  useEffect(() => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/v1/phase`, {}, { headers })
        .then((res) => setGame(res.data.phase));
    } catch (err) {
      console.log(err);
    }
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/v1/student`, { headers })
        .then((res) => setPlayer(res.data.player));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleDisable = () => {
    setDisabled(true);
    setDisabledStyle(true);
    setMessage("Please wait for a new question!");
    setTimeout(() => {
      setDisabledStyle(false);
      setDisabled(false);
      setMessage("");
    }, 2000);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleBuzzerClicked = () => {
    setBuzzerClicked(true);
    setTimeout(() => {
      setBuzzerClicked(false);
    }, 500);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/question/buzzer`,
          {},
          { headers }
        )
        .then((res) => {
          setQuestionAvailable(res.data.success);
          res.data.success === false
            ? handleDisable()
            : (setMessage("Thank You"),
              setTimeout(() => {
                setMessage("");
              }, 2000));
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    player && console.log(player);
  }, [player]);

  return (
    <div className="imgContainer h-100 m-auto">
      <div className="h-100 text-center primary-bg pt-4 playerMainContainer">
        <div className="d-flex justify-content-between mx-3 align-items-center">
          <span className="tabTitle">
            Tab {player && player.player_id} - {player && player.name}
          </span>
          <div onClick={() => refreshPage()}>
            <RefreshIcon />
          </div>
        </div>
        {game === 1 ? (
          <div>
            <div className="answerBtnsRow row mx-auto">
              <TabAnswerBtn
                answer="A"
                disabled={disabled}
                setDisabled={setDisabled}
                setDisabledStyle={setDisabledStyle}
                disabledStyle={disabledStyle}
                setMessage={setMessage}
              />
              <TabAnswerBtn
                answer="B"
                disabled={disabled}
                setDisabled={setDisabled}
                setDisabledStyle={setDisabledStyle}
                disabledStyle={disabledStyle}
                setMessage={setMessage}
              />
              <TabAnswerBtn
                answer="C"
                disabled={disabled}
                setDisabled={setDisabled}
                setDisabledStyle={setDisabledStyle}
                disabledStyle={disabledStyle}
                setMessage={setMessage}
              />
            </div>
            <div className="waitText mt-4">{message}</div>
          </div>
        ) : (
          <>
            <div
              className={
                disabledStyle
                  ? "buzzer disabledBuzzerContainer bottomShadow bottomLightBlueShadow mx-auto"
                  : buzzerClicked
                  ? "buzzer buzzerContainer cardClicked bottomShadow bottomLightBlueShadow mx-auto"
                  : "buzzer buzzerContainer bottomShadow bottomLightBlueShadow mx-auto"
              }
              onClick={() => !disabled && handleBuzzerClicked()}
            >
              Buzzer
            </div>
            <div className="waitText mt-4">{message}</div>
          </>
        )}
      </div>
    </div>
  );
};
export default TabScreen;
