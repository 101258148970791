/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import axios from "axios";

const TabAnswerBtn = ({
  answer,
  disabled,
  setDisabled,
  setMessage,
  disabledStyle,
  setDisabledStyle,
}) => {
  const [qAvailable, setQAvailable] = useState(false);
  const [clcked, setClicked] = useState(false);
  const [cardClicked, setCardClicked] = useState(false);

  const handleDisable = () => {
    setClicked(false);
    setDisabledStyle(true);
    setDisabled(true);
    setMessage("Please wait for a new question!");
    setTimeout(() => {
      setDisabledStyle(false);
      setDisabled(false);
      setMessage("");
    }, 2000);
  };

  const handleBtnClicked = () => {
    setCardClicked(true);
    setTimeout(() => {
      setCardClicked(false);
    }, 500);

    setClicked(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/question/answer`,
          { answer_id: answer === "A" ? 1 : answer === "B" ? 2 : 3 },
          { headers }
        )
        .then((res) => {
          setQAvailable(res.data.success);
          res.data.success === false
            ? handleDisable()
            : (setMessage("Thank You"),
              setTimeout(() => {
                setMessage("");
              }, 2000));
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="col-4">
      <div
        onClick={() => disabled === false && handleBtnClicked()}
        className={
          disabledStyle
            ? "tabAnswerLetterContainerDisabled position-relative bottomShadow bottomLightBlueShadow"
            : clcked && cardClicked
            ? "tabAnswerLetterContainer answerClicked cardClicked position-relative bottomShadow bottomLightBlueShadow"
            : clcked
            ? "tabAnswerLetterContainer answerClicked position-relative bottomShadow bottomLightBlueShadow"
            : "tabAnswerLetterContainer  position-relative bottomShadow bottomLightBlueShadow"
        }
      >
        <h1 className="tabAnswerLetter">{answer}</h1>
      </div>
    </div>
  );
};

export default TabAnswerBtn;
